import React, { Fragment, useState } from 'react';
// Lightbox component
import Carousel, { Modal, ModalGateway } from 'react-images';

import Gallery from './Gallery';

const DestinationGallery = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

  const carouselImages = [];

  images.forEach(document => {
    if (!document.gallery_image) return;
    const image = {
      src: document.gallery_image.url,
    };
    carouselImages.push(image);
  });

  const toggleLightbox = image => {
    setCurrentImage(image);
    setLightboxIsOpen(!lightboxIsOpen);
  };

  return (
    <Fragment>
      <Gallery images={images} handleClick={toggleLightbox} />
      <ModalGateway>
        {lightboxIsOpen ? (
          <Modal onClose={toggleLightbox}>
            <Carousel currentIndex={currentImage} views={carouselImages} />
          </Modal>
        ) : null}
      </ModalGateway>
    </Fragment>
  );
};

export default DestinationGallery;
