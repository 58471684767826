import React from 'react';
import { isMobile } from 'react-device-detect';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    maxHeight: 950,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  image: {
    cursor: 'pointer',
  },
}));

const Gallery = ({ images, handleClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridList cellHeight={400} spacing={1} className={classes.gridList}>
        {images.map((document, index) =>
          document.gallery_image ? (
            <GridListTile
              key={document.gallery_image.url}
              cols={index % 3 === 0 ? 2 : 1}
              rows={index % 3 === 0 ? 2 : 1}
              onClick={() => handleClick(index)}
            >
              {isMobile && (
                <img
                  src={document.gallery_image.thumbnails.mobile.url}
                  alt="destination-gallery"
                  className={classes.image}
                />
              )}
              {!isMobile && (
                <img
                  src={document.gallery_image.url}
                  alt="destination-gallery"
                  className={classes.image}
                />
              )}
            </GridListTile>
          ) : null,
        )}
      </GridList>
    </div>
  );
};

export default Gallery;
