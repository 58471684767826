import React, { Fragment } from 'react';
import { isMobile } from 'react-device-detect';

import { RichText } from 'prismic-reactjs';
// react component for creating beautiful carousel
import Carousel from 'react-slick';
// material-ui components
import { makeStyles } from '@material-ui/core/styles';
// core components
import CustomTabs from 'components/CustomTabs/CustomTabs';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const styles = makeStyles({
  textLeft: {
    textAlign: 'left',
  },
  cardSlide: {
    width: '100%',
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
  },
  slideImage: {
    width: '100%',
    height: '100%',
  },
  cardSlideImage: {
    height: 'auto',
    maxHeight: '355px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const createTabs = tour => {
  const classes = styles();

  let description = '';
  let highlights = '';
  let program = '';
  let prices = '';

  if (tour.primary.description1) {
    description = RichText.render(tour.primary.description1);
  }

  if (tour.primary.highlights) {
    highlights = RichText.render(tour.primary.highlights);
  }

  if (tour.primary.program) {
    program = RichText.render(tour.primary.program);
  }

  if (tour.primary.prices) {
    prices = RichText.render(tour.primary.prices);
  }

  const descriptionTab = {
    tabName: 'Description',
    tabContent: <div className={classes.textLeft}>{description}</div>,
  };

  const highlightsTab = {
    tabName: 'Highlights',
    tabContent: <div className={classes.textLeft}>{highlights}</div>,
  };

  const programTab = {
    tabName: 'Program',
    tabContent: <div className={classes.textLeft}>{program}</div>,
  };
  const pricesTab = {
    tabName: 'Prices',
    tabContent: <div className={classes.textLeft}>{prices}</div>,
  };
  return [descriptionTab, highlightsTab, programTab, pricesTab];
};

const DestinationTour = ({ data }) => {
  const classes = styles();
  const tour = data;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: 0,
  };
  return (
    <Fragment>
      <GridContainer
        className={classes.gridContainer}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <GridItem xs={12} md={6}>
          <CustomTabs headerColor="primary" tabs={createTabs(data)} />
        </GridItem>
        {tour.fields ? (
          <GridItem xs={12} md={6}>
            <Carousel {...settings}>
              {tour.fields.map(document => {
                return (
                  <div className={classes.cardSlide} key={document.id}>
                    {document.carousel._linkType === 'Link.file' && (
                      <video className={classes.slideImage} controls>
                        <source src={document.carousel.url} type="video/mp4" alt="activity-video" />
                      </video>
                    )}
                    {document.carousel._linkType ===
                      'Link.image'(
                        <img
                          src={document.carousel.url}
                          alt="activity-img"
                          className={classes.cardSlideImage}
                        />,
                      )}
                  </div>
                );
              })}
            </Carousel>
          </GridItem>
        ) : null}
      </GridContainer>
    </Fragment>
  );
};

export default DestinationTour;
