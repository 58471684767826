import { container, cardTitle, cardSubtitle, title } from 'assets/jss/material-kit-react';
import imagesStyles from 'assets/jss/material-kit-react/imagesStyles';

const destinationPageStyle = theme => ({
  container: {
    zIndex: '12',
    ...container,
    textAlign: 'center',
  },
  title: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    textDecoration: 'none',
    fontFamily: 'Great Vibes',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  subtitle: {
    maxWidth: '500px',
    margin: '20px auto',
  },
  main: {
    background: '#f5f5f5',
    position: 'relative',
    zIndex: '3',
  },
  mainRaised: {
    margin: '-100px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down('xs')]: {
      margin: '-40px 0px 0px',
    },
  },
  section: {
    padding: '5px 0',
    textAlign: 'center',
  },
  content: {
    color: '#000',
    marginBottom: '20px',
  },
  carousel: {
    height: '100vh',
    maxHeight: '1600px',
    overflow: 'hidden',
    position: 'relative',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    margin: '0',
    padding: '0',
    border: '0',
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    '&:before': {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      zIndex: 2,
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.2) !important',
    },
    '&:after': {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      zIindex: 2,
      position: 'absolute',
    },
    [theme.breakpoints.down('xs')]: {
      height: '40vh',
    },
  },
  slideImage: {
    width: '100%',
    height: '100%',
  },
  gridContainer: {
    padding: '10px 10px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardSlideImage: {
    height: 'auto',
    maxHeight: '355px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  cardTitle: {
    ...cardTitle,
    fontFamily: 'Quattrocento',
  },
  cardSubtitle: {
    ...cardSubtitle,
    marginBottom: '1.5rem',
  },
  cardSlide: {
    width: '100%',
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ...imagesStyles,
  paper: {
    padding: theme.spacing(3, 5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: theme.spacing(1),
    maxHeight: '420px',
    overflow: 'auto',
  },
  destinationPaper: {
    padding: theme.spacing(3, 5),
    marginBottom: theme.spacing(1),
  },
  tourPaper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    border: 'solid #819E84 1.5px',
  },
  discoverTitle: {
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0.5, 0, 0.5),
      fontSize: '1.5rem',
    },
  },
  tourTitle: {
    marginBottom: theme.spacing(3),
    fontStyle: 'italic',
    fontWeight: 'bolder',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
      fontSize: '1rem',
    },
  },
  tourDates: {
    fontWeight: 'bold',
  },
  tourPrice: {
    fontWeight: 'bold',
  },
  prezi: {
    maxWidth: '560px',
    margin: '0 auto 15px',
    '& div': {
      position: 'relative',
      paddingBottom: '56%',
    },
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
});

export default destinationPageStyle;
