import React, { Fragment } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import LazyLoad from 'react-lazyload';
import { isMobile } from 'react-device-detect';

// nodejs library that concatenates classes
import classNames from 'classnames';
// graphql
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
// react component for creating beautiful carousel
import Carousel from 'react-slick';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
// core components
import Button from 'components/CustomButtons/Button';
import Danger from 'components/Typography/Danger';
import Layout from 'components/Layout';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import NavPills from 'components/NavPills/NavPills';
import SEO from 'components/SEO';
import Spinner from 'components/Spinner/Spinner';
// @material-ui/icons
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import Casino from '@material-ui/icons/Casino';
// Activities component
import DestinationActivities from 'components/Destination/DestinationActivities';
// Gallery component
import DestinationGallery from 'components/Destination/DestinationGallery';
// Dialogue component
import DestinationDialogue from 'components/Destination/DestinationDialogue';
// Tour component
import DestinationTour from 'components/Destination/DestinationTour';
// JSX style
import destinationPageStyle from 'assets/jss/material-kit-react/views/destinationPage';

const MemorialIcon = () => {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path d="M10.333 15.748h0.473v-4.312c-0.473 0.46-0.473 1.125-0.473 1.125v3.187zM11.314 15.748h0.465v-4.72c-0.176 0.017-0.331 0.049-0.465 0.099v4.621zM13.194 15.748h0.472v-3.188c0 0 0-0.667-0.472-1.125v4.313zM12.284 15.748h0.403v-4.621c-0.119-0.042-0.252-0.074-0.403-0.094v4.715zM23.599 18.39v-8.958l-4.23 3.319v-2.78h-0.667v-0.792h-1.093v-2.282h-1.107c-0.425-1.554-2.281-2.724-4.502-2.724-2.223 0-4.077 1.17-4.502 2.724h-1.107v2.282h-1.093v0.792h-0.665v2.778l-4.232-3.316v8.958h-0.401v1.437h24v-1.437h-0.401zM2.389 14.624v3.447h-0.807v-3.55c0 0-0.096-1.468 0.422-1.519s0.384 1.622 0.384 1.622zM3.872 15.241v2.831h-0.662v-2.916c0 0-0.079-1.202 0.346-1.246 0.426-0.042 0.317 1.331 0.317 1.331zM12.846 18.39h-1.691v-1.646h1.691v1.646zM14.029 15.981h-4.057v-3.571c0 0 0-1.879 2.029-1.879s2.028 1.879 2.028 1.879v3.571zM20.792 15.155v2.916h-0.661v-2.831c0 0-0.11-1.373 0.315-1.328 0.423 0.040 0.345 1.242 0.345 1.242zM22.418 14.521v3.55h-0.806v-3.447c0 0-0.136-1.674 0.383-1.622s0.423 1.519 0.423 1.519z" />
    </SvgIcon>
  );
};

const BusinessIcon = () => {
  return <BusinessCenter />;
};

const LeisureIcon = () => {
  return <Casino />;
};

const createTabs = (data, lang) => {
  let businessTab;
  let leisureTab;
  let memoryTab;
  let tabs = [];

  const businessLabel = (lang === 'fr-fr') ? 'Business' : 'Business';
  const leisureLabel = (lang === 'fr-fr') ? 'Loisirs' : 'Leisure';
  const memoryLabel = (lang === 'fr-fr') ? 'Mémoires' : 'Memory';

  if (data.body1 && data.body1.length >= 1) {
    businessTab = {
      tabButton: businessLabel,
      tabIcon: BusinessIcon,
      tabContent: <DestinationActivities data={data.body1} />,
      id: 0,
    };
  }

  if (data.body2 && data.body2.length >= 1) {
    leisureTab = {
      tabButton: leisureLabel,
      tabIcon: LeisureIcon,
      tabContent: <DestinationActivities data={data.body2} />,
      id: 1,
    };
  }

  if (data.body3 && data.body3.length >= 1) {
    memoryTab = {
      tabButton: memoryLabel,
      tabIcon: MemorialIcon,
      tabContent: <DestinationActivities data={data.body3} />,
      id: 2,
    };
  }

  if (businessTab !== undefined && leisureTab !== undefined && memoryTab !== undefined) {
    tabs = [businessTab, leisureTab, memoryTab];
  } else if (businessTab !== undefined && leisureTab !== undefined) {
    tabs = [businessTab, leisureTab];
  } else if (businessTab !== undefined && memoryTab !== undefined) {
    tabs = [businessTab, memoryTab];
  } else if (leisureTab !== undefined && memoryTab !== undefined) {
    tabs = [leisureTab, memoryTab];
  } else if (businessTab !== undefined) {
    tabs = [businessTab];
  } else if (leisureTab !== undefined) {
    tabs = [leisureTab];
  } else if (memoryTab !== undefined) {
    tabs = [memoryTab];
  }
  return tabs;
};

const DestinationTemplate = ({ classes, data }) => {
  const settings = {
    className: 'home-slider',
    dots: true,
    lazyload: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const destination = data.prismicDestination.data;
  const { lang } = data.prismicDestination;
  const alternateLanguages = data.prismicDestination.alternate_languages;

  const activeDocMeta = { lang, alternateLanguages };

  const { tours } = data.prismicHomepage.data;

  const menu = data.prismicMenu.data;
  if (!menu) return null;

  if (!destination) return null;
  const description = destination.description ? destination.description : null;
  const name = destination.name ? destination.name : 'Undefined';
  const subtitle = destination.subtitle ? destination.subtitle : '';
  const heroImages = destination.hero_images.length >= 1 ? destination.hero_images : [];

  return (
    <Layout activeDocMeta={activeDocMeta} menu={menu} tours={tours}>
      <SEO
        title={name ? `Discover ${RichText.asText(name.raw)} with Servitours` : `Welcome to Servitours`}
        description={description ? RichText.asText(description.raw) : null}
        image={heroImages ? heroImages[0].image : null}
      />
      <Carousel {...settings}>
        {heroImages ? (
          heroImages.map((document, index) => {
            if (document.image) {
              return (
                <div className={classes.carousel} key={index}>
                  {isMobile && (
                    <img
                      src={document.image.mobile.url}
                      alt={RichText.asText(name.raw)}
                      className={classes.slideImage}
                    />
                  )}
                  {!isMobile && (
                    <img
                      src={document.image.url}
                      alt={RichText.asText(name.raw)}
                      className={classes.slideImage}
                    />
                  )}
                </div>
              );
            }
            return (
              <div className={classes.carousel}>
                <h1>Please add an image to Hero Images</h1>
              </div>
            );
          })
        ) : (
          <div className={classes.carousel}>
            <h1>Please add an image to Hero Images</h1>
          </div>
        )}
      </Carousel>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
            <GridContainer justifyContent="center">
              <GridItem xs={12} id="content">
                <h1 className={classes.title}>{RichText.asText(name.raw)}</h1>
                <Paper className={classes.destinationPaper}>
                  <h3 className={classes.subtitle}>
                    {subtitle ? RichText.asText(subtitle.raw) : null}
                  </h3>
                  <div>{description ? RichText.render(description.raw) : null}</div>
                </Paper>
              </GridItem>
            </GridContainer>
          </div>
          {
            // Destination Tour section
          }
          {destination.body6 && destination.body6.length !== 0 ? (
            <div className={classes.section}>
              {destination.tour_title === null ? null : (
                <Fragment>
                  <LazyLoad height={200} once offset={[-200, 200]} placeholder={<Spinner />}>
                    <Paper className={classes.tourPaper}>
                      <h2 className={classes.discoverTitle}>
                        {lang === 'en-us'
                          ? 'Discover our itinerary in '
                          : 'Découvrez notre circuit en '}
                        {RichText.asText(destination.name.raw)}
                      </h2>
                      {destination.body6.find(slice => slice.slice_type === 'prezi') ? (
                        <div className={classes.prezi}>
                          {destination.body6.find(slice => slice.slice_type === 'prezi').primary
                            .presentation ? (
                              <div>
                              <iframe
                                  title={
                                  destination.tour_title
                                    ? RichText.asText(destination.tour_title.raw)
                                    : 'Tour presentation'
                                }
                                  src={`${RichText.asText(
                                  destination.body6.find(slice => slice.slice_type === 'prezi').primary
                                    .presentation.raw,
                                )}/embed`}
                                  width="560"
                                  height="315"
                                  webkitallowfullscreen="1"
                                  mozallowfullscreen="1"
                                  allowFullScreen="1"
                                />
                            </div>
                          ) : (
                            <Danger>
                              <h5>
                                Please provide the link to the prezi presentation. <br />
                                <a href="https://prez.is/1df0qsw/">
                                  How to get the link ? Click here!
                                </a>
                              </h5>
                            </Danger>
                          )}
                        </div>
                      ) : (
                        <div>
                          <h3 className={classes.tourTitle}>
                            {RichText.asText(destination.tour_title.raw)}
                            <br />
                            <small>{RichText.asText(destination.tour_subtitle.raw)}</small>
                          </h3>
                          {destination.start_date &&
                          destination.end_date &&
                          destination.start_date !== destination.end_date ? (
                            <h4 className={classes.tourDates}>
                              From {destination.start_date} to {destination.end_date}
                            </h4>
                          ) : null}
                          {destination.body6.find(slice => slice.slice_type === 'tour_details') ? (
                            <DestinationTour
                              data={destination.body6.find(
                                slice => slice.slice_type === 'tour_details',
                              )}
                            />
                          ) : null}
                        </div>
                      )}

                      {destination.price && destination.price > 0 ? (
                        <Fragment>
                          <h4 className={classes.tourPrice}>
                            {lang === 'en-us'
                              ? 'ASK FOR A PRIVATE QUOTE FROM'
                              : 'DEMANDER UN DEVIS PERSONNALISÉ À PARTIR DE'}
                          </h4>
                          <h4 className={classes.tourPrice}>{destination.price.raw} €</h4>
                        </Fragment>
                      ) : (
                        <h4 className={classes.tourPrice}>
                          {lang === 'en-us'
                            ? 'ASK FOR A PRIVATE QUOTE'
                            : 'DEMANDER UN DEVIS PERSONNALISÉ'}
                        </h4>
                      )}

                      <AniLink to="/contact" className={classes.navLink} paintDrip hex="#819E84">
                        <Button size="lg" color="primary" className={classes.button}>
                          {lang === 'en-us'
                            ? 'CONTACT US'
                            : 'NOUS CONTACTER'}
                        </Button>
                      </AniLink>
                    </Paper>
                  </LazyLoad>
                </Fragment>
              )}
            </div>
          ) : null}

          {
            // Destination Dialogue section
          }
          {destination.body4 ? (
            <div className={classes.section}>
              <LazyLoad height={200} once offset={[-200, 200]} placeholder={<Spinner />}>
                <DestinationDialogue data={destination.body4} />
              </LazyLoad>
            </div>
          ) : null}
          {// Destination Activities section
          destination.body1 || destination.body2 || destination.body3 ? (
            <div className={classes.section}>
              <div id="navigation-pills">
                <GridContainer justifyContent="center">
                  <GridItem xs={12} sm={12}>
                    <NavPills alignCenter color="primary" tabs={createTabs(destination, lang)} />
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          ) : null}
          {// Destination Dialogue section after activities
          destination.body5 ? (
            <div className={classes.section}>
              <LazyLoad height={200} once offset={[-200, 200]} placeholder={<Spinner />}>
                <DestinationDialogue data={destination.body5} />
              </LazyLoad>
            </div>
          ) : null}
          {// Destination Gallery section
          destination.body7
            ? destination.body7.map(gallery => {
                let galleryName = '';
                if (gallery.primary.name_of_the_gallery !== null) {
                  galleryName = RichText.asText(gallery.primary.name_of_the_gallery.raw);
                }

                return (
                  <div className={classes.section}>
                    <h3>{galleryName}</h3>
                    <LazyLoad height={200} once offset={[-200, 200]} placeholder={<Spinner />}>
                      <DestinationGallery images={gallery.items} />
                    </LazyLoad>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </Layout>
  );
};

export default withStyles(destinationPageStyle)(DestinationTemplate);

export const query = graphql`
  query DestinationBySlug($uid: String!, $lang: String = "fr-fr") {
    prismicDestination(uid: { eq: $uid }, lang: { eq: $lang }) {
      alternate_languages {
        lang
        type
        uid
      }
      data {
        body1 {
          ... on PrismicDestinationDataBody1Activity {
            id
            items {
              carousel {
                link_type
                type
                url
              }
            }
            primary {
              description1 {
                raw
              }
              subtitle1 {
                raw
              }
              title {
                raw
              }
            }
            slice_label
            slice_type
          }
        }
        body2 {
          ... on PrismicDestinationDataBody2Activity {
            id
            items {
              carousel {
                link_type
                type
                url
              }
            }
            primary {
              description1 {
                raw
              }
              subtitle1 {
                raw
              }
              title {
                raw
              }
            }
            slice_label
            slice_type
          }
        }
        body3 {
          ... on PrismicDestinationDataBody3Activity {
            id
            items {
              carousel {
                link_type
                type
                url
              }
            }
            primary {
              description1 {
                raw
              }
              subtitle1 {
                raw
              }
              title {
                raw
              }
            }
            slice_label
            slice_type
          }
        }
        body4 {
          ... on PrismicDestinationDataBody4Dialogue {
            id
            items {
              character {
                url
              }
              character_name {
                raw
              }
            }
            primary {
              audio {
                link_type
                url
              }
              dialogue {
                raw
              }
              subtitle1 {
                raw
              }
              title {
                raw
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicDestinationDataBody4ImageGallery {
            id
            items {
              carousel1 {
                thumbnails {
                  mobile {
                    url
                  }
                }
                url
              }
            }
            slice_label
            slice_type
          }
        }
        body5 {
          ... on PrismicDestinationDataBody5Carousel {
            id
            items {
              carousel1 {
                thumbnails {
                  mobile {
                    url
                  }
                }
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicDestinationDataBody5Dialogue {
            id
            items {
              character {
                url
              }
              character_name {
                raw
              }
            }
            primary {
              audio {
                link_type
                url
              }
              dialogue {
                raw
              }
              subtitle1 {
                raw
              }
              title {
                raw
              }
            }
            slice_label
            slice_type
          }
        }
        body6 {
          ... on PrismicDestinationDataBody6Prezi {
            id
            primary {
              presentation {
                raw
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicDestinationDataBody6TourDetails {
            id
            items {
              carousel {
                link_type
                url
              }
            }
            primary {
              description1 {
                raw
              }
              highlights {
                raw
              }
              prices {
                raw
              }
              program {
                raw
              }
            }
            slice_label
            slice_type
          }
        }
        body7 {
          ... on PrismicDestinationDataBody7ImageGallery {
            id
            items {
              gallery_image {
                thumbnails {
                  mobile {
                    url
                  }
                }
                url
              }
              image_captions {
                raw
              }
            }
            primary {
              name_of_the_gallery {
                raw
              }
            }
            slice_label
            slice_type
          }
        }
        description {
          raw
        }
        end_date
        featured_image {
          url
          link_type
        }
        hero_images {
          image {
            dimensions {
              height
              width
            }
            thumbnails {
              mobile {
                url
              }
            }
            url
          }
        }
        name {
          raw
        }
        price
        region {
          document {
            ... on PrismicRegion {
              id
              data {
                name {
                  raw
                }
              }
            }
          }
          link_type
        }
        start_date
        subtitle {
          raw
        }
        tour_subtitle {
          raw
        }
        tour_title {
          raw
        }
      }
      lang
      type
      uid
    }
    prismicHomepage(lang: { eq: $lang }) {
      data {
        tours {
          destination {
            document {
              ... on PrismicDestination {
                id
                lang
                type
                uid
                url
                data {
                  name {
                    raw
                  }
                }
              }
            }
          }
          message {
            raw
          }
        }
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      data {
        links {
          label {
            raw
          }
          link {
            lang
            type
            url
          }
        }
      }
    }
  }
`;
