import React, { Fragment } from 'react';
import { RichText } from 'prismic-reactjs';

// react component for creating beautiful carousel
import Carousel from 'react-slick';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Card from 'components/Card/Card';
import CardBody from 'components/Card//CardBody';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Spinner from 'components/Spinner/Spinner';
// JSX style
import destinationPageStyle from 'assets/jss/material-kit-react/views/destinationPage';

const DestinationActivities = ({ classes, data }) => {

  const settings = {
    dots: true,
    infinite: true,
    lazyload: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: 0,
  };

  return (
    <Fragment>
      {data.map(document => {
        let title = '';
        let subtitle = '';
        let description = '';

        if (document.primary.title) {
          title = RichText.asText(document.primary.title.raw);
        }

        if (document.primary.subtitle1) {
          subtitle = RichText.asText(document.primary.subtitle1.raw);
        }

        if (document.primary.description1) {
          description = RichText.render(document.primary.description1.raw);
        }

        return (
          <GridContainer
            className={classes.gridContainer}
            key={document.id}
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {document.items ? (
              <GridItem xs={12} sm={7}>
                  <Carousel {...settings}>
                    {document.items.map((file, index) => {
                      if (!file.carousel) {
                        return (
                          <div>
                            <h4>Please add an image to carousel</h4>
                          </div>
                        );
                      }
                      if (file.carousel && file.carousel.url.includes('.mp4')) {
                        return (
                          <div className={classes.cardSlide} key={file.carousel.id}>
                            <video className={classes.slideImage} controls>
                              <source
                                src={file.carousel.url}
                                type="video/mp4"
                                alt="activity-video"
                              />
                            </video>
                          </div>
                        );
                      } else {
                        return (
                          <div className={classes.cardSlide} key={index}>
                            <img
                              src={file.carousel.url}
                              alt="activity-img"
                              className={classes.cardSlideImage}
                            />
                          </div>
                        );
                      }
                    })}
                  </Carousel>
              </GridItem>
            ) : null}

            <GridItem xs={12} sm={5}>
                <Card className={classes.card}>
                  <div className={classes.details}>
                    <CardBody className={classes.content}>
                      <h3 className={classes.cardTitle}>
                        {title} <br />
                        <small>{subtitle}</small>
                      </h3>
                      {description}
                    </CardBody>
                  </div>
                </Card>
            </GridItem>
          </GridContainer>
        );
      })}
    </Fragment>
  );
};

export default withStyles(destinationPageStyle)(DestinationActivities);
