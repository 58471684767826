/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { RichText } from 'prismic-reactjs';

import htmlSerializer from 'utils/htmlSerializer';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    maxHeight: 500,
    overflow: 'auto',
  },
  cover: {
    width: 500,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  cardSlide: {
    width: '100%',
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardSlideImage: {
    height: 'auto',
    maxHeight: '355px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  characterDiv: {
    width: '200px',
  },
  characterImage: {
    height: '160px',
    width: '130px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dialogue: {
    marginTop: theme.spacing(3),
    '& img': {
      height: 'auto',
      maxHeight: '150px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
}));

const DestinationDialogue = ({ data }) => {
  const classes = useStyles();
  const dialogue = data.find(slice => slice.slice_type === 'dialogue');
  const carousel = data.find(slice => slice.slice_type === 'carousel');

  return (
    <div>
      <GridContainer justifyContent="center" alignItems="center">
        {dialogue ? (
          <GridItem xs={12} md={carousel ? 6 : 8}>
            <Card className={classes.card}>
              <div className={classes.details}>
                <CardContent className={classes.content}>
                  <GridContainer justifyContent="center" alignItems="center">
                    {dialogue.primary.title ? (
                      <GridItem xs={12}>
                        <h3>{RichText.asText(dialogue.primary.title.raw)}</h3>
                      </GridItem>
                    ) : null}
                    {dialogue.primary.subtitle1 ? (
                      <GridItem xs={12}>
                        <h5>{RichText.asText(dialogue.primary.subtitle1.raw)}</h5>
                      </GridItem>
                    ) : null}
                    <GridContainer alignContent="space-between">
                      <GridItem xs={6} className={classes.characterDiv}>
                        <img
                          src={dialogue.items[0].character.url}
                          alt="character"
                          className={classes.characterImage}
                        />
                        <h6>
                          {dialogue.items[0].character_name
                            ? RichText.asText(dialogue.items[0].character_name.raw)
                            : ''}
                        </h6>
                      </GridItem>
                      <GridItem xs={6} className={classes.characterDiv}>
                        <img
                          src={dialogue.items[1].character.url}
                          alt="character"
                          className={classes.characterImage}
                        />
                        <h6>
                          {dialogue.items[1].character_name
                            ? RichText.asText(dialogue.items[1].character_name.raw)
                            : ''}
                        </h6>
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                  <hr />
                  <div className={classes.dialogue}>
                    <RichText
                      render={dialogue.primary.dialogue.richText}
                      htmlSerializer={htmlSerializer}
                    />
                  </div>
                </CardContent>
                {dialogue.primary.audio ? (
                  <div className={classes.controls}>
                    <audio id="player" controls>
                      <source src={dialogue.primary.audio.url} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                ) : null}
              </div>
            </Card>
          </GridItem>
        ) : null}
        {carousel ? (
          <GridItem xs={12} md={6}>
            {carousel.items.map(document => (
              <div className={classes.cardSlide}>
                {isMobile && (
                  <img
                    src={document.carousel1.thumbnails.mobile.url}
                    alt="activity-img"
                    className={classes.cardSlideImage}
                  />
                )}
                {!isMobile && (
                  <img
                    src={document.carousel1.url}
                    alt="activity-img"
                    className={classes.cardSlideImage}
                  />
                )}
              </div>
            ))}
          </GridItem>
        ) : null}
      </GridContainer>
    </div>
  );
};

export default DestinationDialogue;
